<template>
     <div>
        <!-- Route Header -->
        <div class="stage-inner-container-padded">
            <b-row class="mb-2 mt-2">
                <b-col cols="12">
                    <div class="d-flex flex-column flex-lg-row flex-xl-row align-items-center">
                        <div class="text-center mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                            <span class="fs-2-5 align-middle">Technician Management</span>
                        </div>
                        <div class="ml-lg-3">
                            <b-button
                                @click="createNewRow()"
                                variant="info"
                                block
                            >
                                Add A Technician
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col xl="6" lg="12">
                    <!-- Table -->
                    <div class="d-flex flex-row mt-2 mb-1">
                        <div class="fs-1-75">
                            Available Technicians
                        </div>
                    </div>

                    <div class="d-flex flex-column flex-xl-row flex-lg-row align-items-stretch bg-vai-gray-300">
                        <div class="d-flex flex-row justify-content-between fs-1-5 bg-vai-gray-700 rounded-right px-2 py-1">
                            <div class="text-light">
                                List Options
                            </div>                            
                        </div>
                        <div class="align-self-center ml-3">
                            <b-form-checkbox
                                v-model="includeInactive"
                                @input="fetchPageData"
                                button-variant="warning"
                                switch
                                size="lg"
                            >Show Inactive</b-form-checkbox>
                        </div>                        
                    </div>

                    <b-table 
                        stacked="md" 
                        striped 
                        hover 
                        small 
                        dark 
                        :items="values" 
                        :fields="tableFields"
                        :sort-null-last="true"
                    >
                        <template v-slot:cell(name)="data">
                            {{ data.item.tech_first_name + ' ' + data.item.tech_last_name }}
                        </template>
                        <template v-slot:cell(upkeep)="data">
                            <div class="d-flex flex-column">
                                <div v-for="itm in data.item.upkeep" :key="itm.item">
                                    <b-badge :variant="itm.variant" class="text-wrap">{{itm.item}} {{itm.fuzzyRemaining}}</b-badge>
                                </div>
                            </div>
                        </template>
                        <template v-slot:cell(controls)="data">
                            <b-dropdown 
                                :id="`options-dd-${data.item.id}`" 
                                text="Options"
                                size="sm"
                                variant="info" 
                            >
                                <b-dropdown-item-button 
                                    @click="openEditTechnician(data.item.id)"
                                >
                                    <b-icon-pencil-square></b-icon-pencil-square> Edit Tech Info
                                </b-dropdown-item-button>
                                <b-dropdown-item-button 
                                    :disabled="true"
                                >
                                    <b-icon-file-arrow-up></b-icon-file-arrow-up> Upload New Documents
                                </b-dropdown-item-button>
                                <b-dropdown-divider v-if="data.item.cert_atch != null || data.item.gauge_atch != null"></b-dropdown-divider>
                                <b-dropdown-header v-if="data.item.cert_atch != null || data.item.gauge_atch != null" :id="`available-files-dd-${data.item.id}`">
                                    Certifications and Certificates
                                </b-dropdown-header>
                                <b-dropdown-item-button 
                                    v-if="data.item.cert_atch != null"
                                    :aria-describedby="`available-files-dd-${data.item.id}`"
                                    @click="downloadDocument(data.item.cert_atch_file_id, data.item.cert_atch_note)"
                                >
                                    <b-icon-download></b-icon-download> Primary Certification
                                </b-dropdown-item-button>
                                <b-dropdown-item-button 
                                    v-if="data.item.gauge_atch != null"
                                    :aria-describedby="`available-files-dd-${data.item.id}`"
                                    @click="downloadDocument(data.item.gauge_atch_file_id, data.item.gauge_atch_note)"
                                >
                                    <b-icon-download></b-icon-download> Gauge Calibration
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </template>
                    </b-table>
                </b-col>

                <b-col xl="6" lg="12">
                    <!-- Outstanding Requests -->
                    <div class="d-flex flex-row mt-2 mb-1">
                        <div class="fs-1-75 align-middle">
                            Technicians Pending Approval <b-badge class="d-none d-lg-inline-block d-xl-inline-block ml-2">{{outstandingForReview}} Outstanding</b-badge>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-xl-row flex-lg-row align-items-stretch bg-vai-gray-300">
                        <div class="d-flex flex-row justify-content-between fs-1-5 bg-vai-gray-700 rounded-right px-2 py-1">
                            <div class="text-light">
                                List Options
                            </div>
                            <div class="d-lg-none d-xl-none">
                                <b-badge class="ml-2">{{outstandingForReview}} Outstanding</b-badge>
                            </div>
                        </div>
                        <div class="align-self-center ml-3">
                            <b-form-checkbox
                                v-model="includeOutstanding"
                                @input="collectVisibleReviewing"
                                button-variant="warning"
                                switch
                                size="lg"
                            >Outstanding</b-form-checkbox>
                        </div>
                        <div class="align-self-center ml-3">
                            <b-form-checkbox
                                v-model="includeAccepted"
                                @input="collectVisibleReviewing"
                                switch
                                size="lg"
                            >Accepted</b-form-checkbox>
                        </div>
                        <div class="align-self-center ml-3">
                            <b-form-checkbox
                                v-model="includeRejected"
                                @input="collectVisibleReviewing"
                                switch
                                size="lg"
                            >Rejected</b-form-checkbox>
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div 
                            v-for="row, idx in visibleReviewing" 
                            :key="row.id" 
                            :class="`d-flex flex-row justify-content-between align-items-center py-2 px-1 ${(idx % 2 == 0) ? 'bg-vai-gray-100 ' : 'bg-vai-gray-200 '}`"
                        >
                            <div class="fs-1-25">
                                {{row.title}}
                            </div>
                            <div v-if="row.reviewed == false" class="fs-1-25">
                                <b-badge variant="vai-secondary-medium">Awaiting Review</b-badge>
                            </div>
                            <div v-if="row.reviewed == true && row.rejected == false" class="fs-1-25">
                                <b-badge variant="success" :id="`review-accepted-${row.id}`">Accepted</b-badge>
                                <b-popover :target="`review-accepted-${row.id}`" triggers="hover" placement="left">
                                    <template #title>Submission Accepted</template>
                                    <b>Accepted By:</b><br>{{row.reviewed_by_name}}, {{row.reviewed_on_fuzzy}}<br>
                                </b-popover>
                            </div>
                            <div v-if="row.reviewed == true && row.rejected == true" class="fs-1-25">
                                <b-badge variant="danger" :id="`review-rejected-${row.id}`">Rejected</b-badge>
                                <b-popover :target="`review-rejected-${row.id}`" triggers="hover" placement="left">
                                    <template #title>Submission Rejected</template>
                                    <b>Rejected By:</b><br>{{row.reviewed_by_name}}, {{row.reviewed_on_fuzzy}}<br>
                                    <hr>
                                    <b>Rejection Reason:</b><br>
                                    {{row.rejection_reason}}
                                </b-popover>
                            </div>
                        </div>
                    </div>
                </b-col>
                <!-- <b-col xl="6" lg="12"> 
                    <dyn-form v-for="tech in selectedTechnician" :key="tech.id"
                        :inspectionTarget="tech.id" 
                        :formID="formID"
                        :showFlags="false"
                        :backOnDiscard="false"
                        :runOnDiscard="closeRow"
                    ></dyn-form>
                </b-col> -->
            </b-row>
        </div>
        <b-modal
            id="create-technician"
            v-model="showCreateTechModal" 
            hide-footer 
            title="Add New Technician" 
            size="lg"
            @close="clearModalValues"
        >
            <b-row v-show="!sending">
                <b-col cols="12">
                    <h3>
                        First, We'll Need Some Information
                        <small class="text-muted d-none d-lg-inline-block d-xl-inline-block">Name, Email, Etc...</small>
                    </h3>
                </b-col>
                <b-col cols="12" class="mt-0 d-block d-lg-none d-xl-none">
                    <h3><small class="text-muted">Name, Email, Etc...</small></h3>
                </b-col>
                <b-col cols="12">
                    <b-row>
                        <b-col lg="6" md="12">
                            <b-form-group
                                id="tech-first-name-label"
                                description="Just First Name Please"
                                label="First Name"
                                label-for="tech-first-name"
                                :invalid-feedback="notNullFeedback('first')"
                                :state="notNullState('first')"
                            >
                                <b-form-input id="tech-first-name" v-model="newTech.first" @input="startShowingState('first')" :state="notNullState('first')" trim></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group
                                id="tech-last-name-label"
                                description="Just Last Name Please"
                                label="Last Name"
                                label-for="tech-last-name"
                                :invalid-feedback="notNullFeedback('last')"
                                :state="notNullState('last')"
                            >
                                <b-form-input id="tech-last-name" v-model="newTech.last" @input="startShowingState('last')" :state="notNullState('last')" trim></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                id="tech-company-label"
                                description="Company, Utility, or Other Employer Name"
                                label="Company"
                                label-for="tech-company"
                                :invalid-feedback="notNullFeedback('company')"
                                :state="notNullState('company')"
                            >
                                <b-form-input 
                                    id="tech-company" 
                                    v-model="newTech.company" 
                                    @input="startShowingState('company')" 
                                    :state="notNullState('company')" trim
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group
                                id="tech-email-label"
                                description="Make sure the best one is the first one"
                                label="Email(s)"
                                label-for="tech-email"
                                :invalid-feedback="newEmailFeedback"
                            >
                                <draggable
                                    :list="newTech.email"
                                    :group="{ name: 'email' }"
                                >
                                    <b-input-group class="my-1" v-for="email, idx in newTech.email" :key="email">
                                        <template #prepend>
                                            <b-input-group-text class="movable"><b-icon-arrow-down-up></b-icon-arrow-down-up></b-input-group-text>
                                        </template>
                                        <b-form-input 
                                            :id="`tech-email-${idx}`" 
                                            v-model="newTech.email[idx]" 
                                            @change="removeEmailOnEmpty"
                                            :state="emailState(idx)" 
                                            trim
                                        ></b-form-input>
                                    </b-input-group>
                                </draggable>
                                <b-form-input 
                                    :id="`tech-email-new`" 
                                    v-model="newEmail"
                                    v-on:keydown.stop="preventEnterDefault" 
                                    v-on:keyup.stop="createNewOnValidEnter"
                                    @blur="addNewEmailIfPossible"
                                    :state="newEmailState" 
                                    trim
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group
                                id="tech-phone-label"
                                description="Make sure the best one is the first one"
                                label="Phone Number(s)"
                                label-for="tech-phone"
                                :invalid-feedback="newPhoneFeedback"
                            >
                                <draggable
                                    :list="newTech.phone"
                                    :group="{ name: 'phone' }"
                                >
                                    <b-input-group class="my-1" v-for="phone, idx in newTech.phone" :key="idx">
                                        <template #prepend>
                                            <b-input-group-text class="movable"><b-icon-arrow-down-up></b-icon-arrow-down-up></b-input-group-text>
                                        </template>
                                        <b-form-input 
                                            :id="`tech-phone-${idx}`" 
                                            v-model="newTech.phone[idx]" 
                                            v-on:keydown.stop="improvePhoneBackSpace($event, idx)" 
                                            @change="removePhoneOnEmpty"
                                            :formatter="phoneFormatter"
                                            :state="phoneState(idx)" 
                                            trim
                                        ></b-form-input>
                                    </b-input-group>
                                </draggable>
                                <b-form-input 
                                    :id="`tech-phone-new`" 
                                    v-model="newPhone"
                                    v-on:keydown.stop="improvePhoneBackSpace($event, 'new')" 
                                    v-on:keyup.stop="createNewPhoneOnValidEnter"
                                    @blur="addNewPhoneIfPossible"
                                    :formatter="phoneFormatter"
                                    :state="newPhoneState" 
                                    trim
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" class="mt-2">
                    <hr>
                    <h3>
                        Next, Testing Certificate
                        <small class="text-muted">This will be verified</small>
                    </h3>
                </b-col>
                <b-col cols="12">
                    <b-row>
                        <b-col lg="6" md="12">
                            <b-form-group
                                id="tech-cert-date-label"
                                description=""
                                label="Cert Date"
                                label-for="tech-cert-date"
                                :invalid-feedback="notNullFeedback('certdate')"
                                :state="notNullState('certdate')"
                            >
                                <b-form-input 
                                    id="tech-cert-date" 
                                    type="date"
                                    v-model="newTech.certdate" 
                                    @input="startShowingState('certdate')" 
                                    :state="notNullState('certdate')" 
                                    min="2000-01-01"
                                    max="2050-01-01"
                                ></b-form-input>

                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group
                                id="tech-cert-num-label"
                                description=""
                                label="Cert Number"
                                label-for="tech-cert-num"
                                :invalid-feedback="notNullFeedback('certnum')"
                                :state="notNullState('certnum')"
                            >
                                <b-form-input 
                                    id="tech-cert-num" 
                                    v-model="newTech.certnum" 
                                    @input="startShowingState('certnum')" 
                                    :state="notNullState('certnum')" 
                                    trim
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group
                                id="tech-cert-expiry-label"
                                description=""
                                label="Cert Expiry Date"
                                label-for="tech-cert-expiry"
                                :invalid-feedback="notNullFeedback('certexpires')"
                                :state="notNullState('certexpires')"
                            >
                                <b-form-input 
                                    id="tech-cert-expiry" 
                                    type="date"
                                    v-model="newTech.certexpires" 
                                    @input="startShowingState('certexpires')" 
                                    :state="notNullState('certexpires')"       
                                    min="2000-01-01"
                                    max="2050-01-01"></b-form-input>
                               
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <upload-file-drop
                                title="Testing Certification"
                                acceptsText="PDF (Preferred), Photo, or Scan"
                                :acceptsTypes="'image/*,.pdf,application/pdf'"
                                :collapseOnUpload="true"
                                :useEmit="false"
                                :uploadCallback="techCertUploaded"
                                :markInvalid="techCertInvalid"
                            ></upload-file-drop>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" class="mt-2">
                    <hr>
                    <h3>
                        Finally, Gauge Certification
                        <small class="text-muted">This will also be verified</small>
                    </h3>
                </b-col>
                <b-col cols="12">
                    <b-row>
                        <b-col lg="4" md="12">
                            <b-form-group
                                id="tech-gauge-mfg-label"
                                description=""
                                label="Gauge Manufacturer"
                                label-for="tech-gauge-mfg"
                                :invalid-feedback="notNullFeedback('gaugemfg')"
                                :state="notNullState('gaugemfg')"
                            >
                                <b-form-input 
                                    id="tech-gauge-mfg" 
                                    v-model="newTech.gaugemfg" 
                                    @input="startShowingState('gaugemfg')" 
                                    :state="notNullState('gaugemfg')" 
                                    trim
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" md="12">
                            <b-form-group
                                id="tech-gauge-model-label"
                                description=""
                                label="Gauge Model"
                                label-for="tech-gauge-model"
                                :invalid-feedback="notNullFeedback('gaugemdl')"
                                :state="notNullState('gaugemdl')"
                            >
                                <b-form-input 
                                    id="tech-gauge-model" 
                                    v-model="newTech.gaugemdl" 
                                    @input="startShowingState('gaugemdl')" 
                                    :state="notNullState('gaugemdl')" 
                                    trim
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" md="12">
                            <b-form-group
                                id="tech-gauge-serial-label"
                                description=""
                                label="Gauge Serial Number"
                                label-for="tech-gauge-serial"
                                :invalid-feedback="notNullFeedback('gaugeserial')"
                                :state="notNullState('gaugeserial')"
                            >
                                <b-form-input 
                                    id="tech-gauge-serial" 
                                    v-model="newTech.gaugeserial" 
                                    @input="startShowingState('gaugeserial')" 
                                    :state="notNullState('gaugeserial')" 
                                    trim
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group
                                id="tech-gauge-date-label"
                                description=""
                                label="Calibration Date"
                                label-for="tech-gauge-date"
                                :invalid-feedback="notNullFeedback('gaugedate')"
                                :state="notNullState('gaugedate')"
                            >

                                <b-form-input 
                                    id="tech-gauge-date" 
                                    type="date"
                                    v-model="newTech.gaugedate" 
                                    @input="startShowingState('gaugedate')" 
                                    :state="notNullState('gaugedate')" 
                                    min="2000-01-01"
                                    max="2050-01-01"
                                ></b-form-input>

                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <upload-file-drop
                                title="Gauge Calibration"
                                acceptsText="PDF (Preferred), Photo, or Scan"
                                :acceptsTypes="'image/*,.pdf,application/pdf'"
                                :collapseOnUpload="true"
                                :useEmit="false"
                                :uploadCallback="gaugeCalibrationUploaded"
                                :markInvalid="gaugeCertInvalid"
                            ></upload-file-drop>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" class="mt-2 mb-2">
                    <hr>
                    <div class="d-flex flex-row justify-content-between">
                        <div>
                            <b-button
                                :disabled="sending"
                                variant="warning"
                                @click="cancelTechEntry"
                            >
                                Cancel
                            </b-button>
                        </div>
                        <div>
                            <b-button
                                :disabled="sending"
                                variant="success"
                                @click="attemptSend"
                            >
                                Submit For Review
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div v-show="sending">
                <div class="d-flex justify-content-center align-items-center mt-4 overlay-top">
                    <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                    <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                    <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
                </div>
                <div class="d-flex justify-content-center h3 mt-4">
                    <span>Submitting Technician Info For Review</span>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="alter-technician"
            v-model="showEditTechModal" 
            hide-footer 
            title="Modify Technician" 
            size="lg"
            @close="closeRow"
        >
            <div v-if="showEditTechModal && editingTech != null" class="mb-3">
                <dyn-form 
                    :inspectionTarget="editingTech" 
                    :formID="formID"
                    :showFlags="false"
                    :backOnDiscard="false"
                    :runOnDiscard="closeRow"
                ></dyn-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
const butils = require('../libs/basicUtils.js');
const _ = butils.underscore;
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
var fileDownload = require('js-file-download');

var dayjs = require('dayjs');
var customParseFormat = require("dayjs/plugin/customParseFormat");


import phoneNumberLib from '../libs/phone_numbers.js'
import draggable from 'vuedraggable'
import uploadFile from '@/components/UploadFileDrop.vue';

export default {
    name: 'technicians',
    components:{
        'dyn-form': () => import('../components/DynamicForm.vue'),
        'upload-file-drop': uploadFile,
        draggable
    },
    data(){
        const now = new Date();
        const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        return{
            name_pluaral: 'Technicians',
            api_path: 'technicians',
            sending: false,
            showEditTechModal: false,
            editingTech: null,
            tableFields: [],
            values: [],
            selectedTechnician: [],
            rawReviewing: [],
            visibleReviewing: [],
            reviewingFields: [],
            includeOutstanding: true,
            includeAccepted: false,
            includeRejected: true,
            includeInactive: false,
            formID: null,
            showCreateTechModal: false,
            newEmail: null,
            newEmailState: null,
            newEmailFeedback: null,
            newPhone: null,
            newPhoneState: null,
            newPhoneFeedback: null,
            newTech:{
                first: null,
                last: null,
                company: null,
                phone: [],
                email: [],
                certnum: null,
                certdate: null,
                certexpires: null,
                certfile: null,
                gaugemfg: null,
                gaugemdl: null,
                gaugeserial: null,
                gaugedate: null,
                gaugefile: null,
            },
            showNewTechState:{
                first: false,
                last: false,
                company: false,
                phone: false,
                email: false,
                certnum: null,
                certdate: null,
                certexpires: null,
                gaugemfg: null,
                gaugemdl: null,
                gaugeserial: null,
                gaugedate: null
            },
            techCertInvalid: false,
            gaugeCertInvalid: false,
            today: todayDate
        }
    },
    methods:{
        downloadDocument(fileID, fileName){
            butils.customInstance.timeoutAndBlob(30000).get(`${process.env.VUE_APP_FILE_API_BASE_URL}/${fileID}`)
            .then((response)=>{
                fileDownload(response.data, fileName);
            })
            .catch((err)=>{
                if(butils.isError404(err)){
                    butils.createToast(this, `Attachment Not Found`, `File ID: ${fileID} Was Expected But Was Not Found`, 'warning');
                }else if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permission To Fetch Attachment Is Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Fetching Document', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        improvePhoneBackSpace(e, idx){
            if(e.key == "Enter"){
                e.preventDefault();
            }else if(e.key == "Backspace"){
                if(e.target.selectionStart == e.target.selectionEnd){
                    if(idx == 'new'){
                        if(e.target.selectionStart == this.newPhone.length){
                            e.preventDefault();
                            var res = phoneNumberLib.chop(this.newPhone)
                            res = _.initial(res);
                            this.newPhone = this.phoneFormatter(res.join(''));
                        }
                    }else{
                        if(e.target.selectionStart == this.newTech.phone[idx]){
                            e.preventDefault();
                            var res = phoneNumberLib.chop(this.newTech.phone[idx])
                            res = _.initial(res);
                            this.newTech.phone[idx] = this.phoneFormatter(res.join(''));
                        }
                    }
                }
            }
        },
        cancelTechEntry(){
            if(this.sending){
                butils.createToast(this, "Cannot Cancel Creation", "Technician Data Aleady Sent", "danger", 10);
            }else{
                // Clear The Values
                this.showCreateTechModal = false;
                this.clearModalValues();
            }
        },
        attemptSend(){
            if(this.validate()){
                // All good, we can send the technician data for review
                this.sending = true;
                var techPacked = this.newTech;
                butils.customInstance.timeoutLength(30000).post(`${process.env.VUE_APP_API_BASE_URL}/technicians/review`, techPacked)
                .then((response)=>{
                    butils.createToast(this, 'Request Sent', 'A Valve Ai Staff Member Will Review The Request Shortly. We strive to review technician creation requests within one business day.', 'success');
                    this.sending = false;
                    this.showCreateTechModal = false;
                    this.fetchPageData();
                    this.cancelTechEntry();
                })
                .catch((err)=>{
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Submit Technician For Review Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                    }else{
                        console.log(err);
                        butils.createToast(this, 'An Error Occured While Submitting Technician For Review', 'Try again, if the problem persists, contact support', 'danger');
                    }
                    this.sending = false;
                })
            }else{
                // Did not validate, wait for user to try again
            }
        },
        validate(){
            var allRequiredComplete = true;
            if(this.newTech.certfile != null){
                this.techCertInvalid = false;
            }else{
                allRequiredComplete = false;
                this.techCertInvalid = true;
            }
            if(this.newTech.gaugefile != null){
                this.gaugeCertInvalid = false;
            }else{
                allRequiredComplete = false;
                this.gaugeCertInvalid = true;
            }
            // Check All Required Fields
            if(this.newTech.first == null){
                allRequiredComplete = false;
                this.showNewTechState.first = true;
            }
            if(this.newTech.last == null){
                allRequiredComplete = false;
                this.showNewTechState.last = true;
            }
            if(this.newTech.certnum == null){
                allRequiredComplete = false;
                this.showNewTechState.certnum = true;
            }
            if(this.newTech.certdate == null){
                allRequiredComplete = false;
                this.showNewTechState.certdate = true;
            }
            if(this.newTech.certexpires == null){
                allRequiredComplete = false;
                this.showNewTechState.certexpires = true;
            }
            if(this.newTech.certexpires == null){
                allRequiredComplete = false;
                this.showNewTechState.certexpires = true;
            }
            if(this.newTech.gaugemfg == null){
                allRequiredComplete = false;
                this.showNewTechState.gaugemfg = true;
            }
            if(this.newTech.gaugemdl == null){
                allRequiredComplete = false;
                this.showNewTechState.gaugemdl = true;
            }
            if(this.newTech.gaugeserial == null){
                allRequiredComplete = false;
                this.showNewTechState.gaugeserial = true;
            }
            if(this.newTech.gaugedate == null){
                allRequiredComplete = false;
                this.showNewTechState.gaugedate = true;
            }

            return allRequiredComplete;
        },
        gaugeCalibrationUploaded(fileID){
            this.newTech.gaugefile = fileID;
        },
        techCertUploaded(fileID){
            this.newTech.certfile = fileID;
        },
        phoneFeedback(){
            if(this.newPhoneFeedback != null){
                return this.newPhoneFeedback;
            }else{
                return null;
            }
        },
        phoneFormatter(value){
            return phoneNumberLib.format(value)
        },
        removePhoneOnEmpty(){
            this.newTech.phone = _.filter(this.newTech.phone, (phone)=>{ return phone != '' && phone != null; })
        },
        removeEmailOnEmpty(){
            this.newTech.email = _.filter(this.newTech.email, (email)=>{ return email != '' && email != null; })
        },
        createNewPhoneOnValidEnter(e){
            if(e.key == "Enter"){
                e.preventDefault();
                this.addNewPhoneIfPossible();
            }
        },
        addNewPhoneIfPossible(){
            if(this.newPhone == null || this.newPhone.length == 0){
                this.newPhoneFeedback = null;
                this.newPhoneState = null;
                this.newPhone = null;
            }else{
                if(phoneNumberLib.clean(this.newPhone).length < 10){
                    this.newPhoneState = false;
                    this.newPhoneFeedback = "Invalid Phone Number"
                }else if(_.find(this.newTech.phone, (phone)=>{ return phone === this.newPhone; }) != undefined){
                    this.newPhoneState = false;
                    this.newPhoneFeedback = "This Phone Number Already Added"
                }else{
                    this.newPhoneFeedback = null;
                    this.newPhoneState = null;
                    this.newTech.phone.push(this.newPhone);
                    this.newPhone = null;
                }
            }
        },
        createNewOnValidEnter(e){
            if(e.key == "Enter"){
                e.preventDefault();
                this.addNewEmailIfPossible();
            }
        },
        addNewEmailIfPossible(){
            if(this.newEmail == null || this.newEmail.length == 0){
                this.newEmailState = null;
                this.newEmailFeedback = null;
                this.newEmail = null;
            }else{
                if(butils.validate.isEmail(this.newEmail) == false){
                    this.newEmailState = false;
                    this.newEmailFeedback = "Invalid Email Address"
                }else if(_.find(this.newTech.email, (email)=>{ return email === this.newEmail; }) != undefined){
                    this.newPhoneState = false;
                    this.newEmailFeedback = "This Email Already Added"
                }else{
                    this.newEmailState = null;
                    this.newEmailFeedback = null;
                    this.newTech.email.push(this.newEmail);
                    this.newEmail = null;
                }
            }
        },
        preventEnterDefault(e){
            if(e.key == "Enter"){
                e.preventDefault();
            }
        },
        phoneState(idx){
            if(this.newTech.phone[idx].length == 0){
                return false;
            }else if(phoneNumberLib.clean(this.newTech.phone[idx]).length < 10){
                return false;
            }else{
                return true;
            }
        },
        emailState(idx){
            if(idx == "new"){
                return butils.validate.isEmail(this.newEmail)
            }else{
                return butils.validate.isEmail(this.newTech.email[idx])
            }
            
        },
        startShowingState(key){
            this.showNewTechState[key] = true;
        },
        notNullState(key){
            if(this.newTech[key] != null){
                return true;
            }else if(this.showNewTechState[key]){
                return false;
            }else{
                return null;
            }
        },
        notNullFeedback(key){
            if(this.newTech[key] != null){
                return null;
            }else{
                return "Required Field, Cannot Be Blank"
            }
        },
        createNewRow(){
            this.showCreateTechModal = !this.showCreateTechModal;
        },
        openEditTechnician(techID){
            this.editingTech = techID;
            this.showEditTechModal = true;
        },
        closeRow(){
            this.editingTech = null;
            this.showEditTechModal = false;
        },
        getTechnicianFormID(){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: 'technicians'});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return defaultFormForType[0].id
            }
        },
        formatPhoneNumber(input){
            return phoneNumberLib.format(input);
        },
        createNewHoverStart(evt){
            evt.target.classList.add('add-link-hover')
        },
        createNewHoverEnd(evt){
            evt.target.classList.remove('add-link-hover')
        },
        collectVisibleReviewing(){
            var tmpSet = _.filter(this.rawReviewing, (rev)=>{ 
                if(this.includeOutstanding && !rev.reviewed){
                   return true; 
                }
                if(this.includeRejected && rev.rejected != null && rev.rejected){
                    return true;
                }
                if(this.includeAccepted && rev.rejected != null && !rev.rejected){
                    return true;
                }
                return false;
            });
            tmpSet.sort((a, b)=>{ 
                if(a.reviewed_on != null && b.reviewed_on != null){
                    return new Date(b.reviewed_on) - new Date(a.reviewed_on)
                }else if(a.reviewed_on == null && b.reviewed_on == null){
                    // Consider The Added On Date added_on
                    return new Date(b.added_on) - new Date(a.added_on)
                }else if(a.reviewed_on == null){
                    // a is null, b has been reviewed
                    return 1;
                }else{
                    // b is null, a has been reviewed
                    return 1;
                }
            })
            this.visibleReviewing = tmpSet;
        },
        fetchPageData () {
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/technicians/review`)
            .then((response)=>{
                this.rawReviewing = [];
                response.data.result.forEach((row)=>{
                    row.title = `${row.tech_data.first} ${row.tech_data.last} - Submitted ${butils.formatters.timestampToFuzzyTime(row.added_on, true)}`
                    row.reviewed_on_formatted = butils.formatters.timestampToDateTime(row.reviewed_on, false);
                    row.reviewed_on_fuzzy = butils.formatters.timestampToFuzzyTime(row.reviewed_on, true)
                })
                this.rawReviewing = response.data.result;
                this.collectVisibleReviewing();
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permission To List Technicians Currently Under Review Is Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Listing Technicians Currently Under Review', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
            //Get User Token and Fetch The Values Required For This Page
            instance.get(`${process.env.VUE_APP_API_BASE_URL}/technicians/list`)
                .then( (response) => {
                    this.tableFields = [];
                    // Set Table Fields
                    this.tableFields.push({ key: 'name', label: 'Full Name', sortable: true });
                    this.tableFields.push({ key: 'tech_company', label: 'Company', sortable: true });
                    this.tableFields.push({ key: 'upkeep', label: 'Upkeep', sortable: true });
                    this.tableFields.push({key: "controls", sortable: false, type: "NULL"});

                    var tmpTechs = response.data.result.records;
                    var activeTechs = [];

                    tmpTechs.forEach((tech)=>{

                        
                        tech.upkeep = [];

                        // Cert Stuff              
                        try {
                           
                            if (_.has(tech.certs, ['backflow_tester'])) {
                                let expires;
                                const dateFormats = ['MM-DD-YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY'];
                                
                                if (_.has(tech.certs.backflow_tester, 'exp')) {
                                    expires = dayjs(tech.certs.backflow_tester.exp, dateFormats);
                                } else if (_.has(tech.certs.backflow_tester, 'date')) {
                                    let rawDate = dayjs(tech.certs.backflow_tester.date, dateFormats);
                                    tech.certs.backflow_tester.exp = rawDate.add(2, 'years').format('MM-DD-YYYY');
                                    expires = dayjs(tech.certs.backflow_tester.exp, dateFormats);
                                }

                                if (expires && !expires.isValid()) {
                                    tech.upkeep.push({
                                        item: "Invalid Certification Date",
                                        daysRemaining: -1,                                        
                                    });
                                }

                                if (expires && expires.isValid()) {
                                    let daysRemaining = expires.diff(dayjs(), 'day');
                                    tech.upkeep.push({
                                        item: "Due For Re-Certification",
                                        daysRemaining: daysRemaining,
                                        fuzzyRemaining: butils.formatters.timestampToFuzzyTime(expires.toDate(), true)
                                    });
                                }
                            }
                        
                        } catch (error) {
                            console.error(error);
                        }
                        

                        // Gauge Stuff
                        if(tech.test_kit_cal_date != null){
                            var expires = new Date(tech.test_kit_cal_date)
                            expires.setFullYear(expires.getFullYear() + 1);
                            var daysRemaining = Math.floor((expires - (new Date())) / (1000 * 60 * 60 * 24));
                            tech.upkeep.push({
                                item: "Gauge Calibration Due",
                                daysRemaining: daysRemaining,
                                fuzzyRemaining: butils.formatters.timestampToFuzzyTime(expires, true)
                            })
                        }
                   
                        tech.upkeep.forEach((uk)=>{
                            if(uk.daysRemaining <= 30 && uk.daysRemaining >= 0){
                                uk.variant = "warning";
                                
                            }else if(uk.daysRemaining < 0){
                                uk.variant = "danger";                               
                            }else{
                                uk.variant = "success";                               
                            }
                        })

                        if(this.includeInactive || (!this.includeInactive && !tech.inactive)) {
                            activeTechs.push(tech);                            
                        }
                    })

                    this.values = activeTechs;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        clearModalValues(){
            this.newEmail = null
                this.newEmailState =  null;
                this.newPhone =  null;
                this.newPhoneState =  null;
                this.newPhoneFeedback =  null;
                this.newTech = {
                    first: null,
                    last: null,
                    company: null,
                    phone: [],
                    email: [],
                    certnum: null,
                    certdate: null,
                    certexpires: null,
                    certfile: null,
                    gaugemfg: null,
                    gaugemdl: null,
                    gaugeserial: null,
                    gaugedate: null,
                    gaugefile: null,
                },
                this.showNewTechState = {
                    first: false,
                    last: false,
                    company: false,
                    phone: false,
                    email: false,
                    certnum: null,
                    certdate: null,
                    certexpires: null,
                    gaugemfg: null,
                    gaugemdl: null,
                    gaugeserial: null,
                    gaugedate: null
                },
                this.techCertInvalid =  false;
                this.gaugeCertInvalid =  false;
        }
    },
    beforeMount (){
        this.api_path = 'technicians';
        this.formID = this.getTechnicianFormID();
        // Get Page Data And Reset Modal
        this.fetchPageData();
    },
    computed:{
        outstandingForReview: function(){
            var tmp = _.filter(this.rawReviewing, (raw)=>{ return raw.reviewed == false; })
            return tmp.length;
        },
    }
}
</script>

<style scoped>
.hoverable{
    cursor:pointer;
}
.clickable{
    cursor: pointer;
}
.movable{
    cursor: move;
}
</style>
